import React from 'react';

const MainScreen = () => {

    return (
        <div>
        </div>
    );

};

export default MainScreen;
