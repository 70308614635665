import React from 'react';
import TransactionsScreen from '../transactions';

const headers = [
    { id: 'reference', label: 'Referencia', sortable: true },
    { id: 'amount', label: 'Monto', sortable: true },
    { id: 'operation_number', label: 'Número de operación', sortable: true },
    { id: 'operation', label: 'Operación', sortable: true },
    { id: 'card_number', label: 'Tarjeta', sortable: true },
];

const mapData = item => ({
    amount: item.amount,
    card_number: item.card_number,
    date_operation: item.date_operation,
    operation: item.operation,
    operation_number: item.operation_number,
    origen: item.origen,
    reference: item.reference
});

const TransactionsParkingLotsScreen = () => {
    return (
        <TransactionsScreen
            headers={headers}
            apiEndpoint="users/bank_terminal_transactions_between_dates/"
            apiEndpointDownload="users/bank_terminal_transactions_between_dates_report/"
            apiEndpointDownloadPDF="users/bank_terminal_transactions_between_dates_report_pdf/"
            mapData={mapData}
        />
    );
};

export default TransactionsParkingLotsScreen;
