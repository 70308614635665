import React from 'react';
import TransactionsScreen from '../transactions';

const headers = [
    { id: 'ticketcode', label: 'Boleto', sortable: true },
    { id: 'checkin', label: 'Entrada', sortable: true },
    { id: 'checkout', label: 'Salida', sortable: true },
    { id: 'amountparking', label: 'Monto', sortable: true },
    { id: 'iduser', label: 'Usuario', sortable: true },
    { id: 'payment', label: 'Método de pago', sortable: true }
];

const mapData = item => ({
    ticketcode: item.ticketcode,
    checkin: item.checkin,
    checkout: item.checkout,
    amountparking: item.amountparking,
    iduser: item.iduser.email,
    payment: item.payment.type
});

const TransactionsParkingLotsScreen = () => {
    return (
        <TransactionsScreen
            headers={headers}
            apiEndpoint="users/transactions_between_dates/"
            apiEndpointDownload="users/transactions_between_dates_report/"
            mapData={mapData}
        />
    );
};

export default TransactionsParkingLotsScreen;
