import React from "react"
import { Navigate } from "react-router-dom";
import { useSessionContext } from 'provider';

const PublicRoute = ({ children }) => {

    const { token } = useSessionContext();

    return (token)
        ? <Navigate to='/main' />
        : children;
}

export default PublicRoute;
