import React, { useEffect, useState } from 'react';
import { Screen, CustomTable, DateRangePicker } from 'components';
import { useApiContext } from 'provider';
const TransactionsValetScreen = () => {

    const [headers] = useState([
        { id: 'consecutive_number', label: 'Consecutivo', sortable: true },
        { id: 'plates', label: 'Placas', sortable: true },
        { id: 'ticket_code', label: 'Boleto de estacionamiento', sortable: true },
        { id: 'return_date', label: 'Fecha de regreso', sortable: true },
        { id: 'zone_parking', label: 'Zona de estacionado', sortable: true },
        { id: 'status', label: 'Estatus', sortable: true },
    ]);

    const [body, setBody] = useState([]);

    const sd = new Date();
    const startDay = sd.getUTCDate() > 15 ? '15' : '01';
    const [startDate, setStartDate] = useState(`${sd.getFullYear()}-${(`0${sd.getMonth() + 1}`).slice(-2)}-${startDay} 00:00:00`);
    const [endDate, setEndDate] = useState(`${new Date().toISOString().slice(0, 10)} 23:59`);

    const { get } = useApiContext();

    const handleStartDateChange = date => setStartDate(date);
    const handleEndDateChange = date => setEndDate(date);

    const buildStatus = status => {
        switch (status) {
            case 1:
                return 'Estacionado';
            case 15:
                return 'Solicitado por usuario';
            case 16:
                return 'Entregado'
            default:
                return 'Estacionado';
        }
    }

    useEffect(() => {

        const onSuccessListVehicles = response => {
            setBody(response.map(item => ({
                ...item,
                zone_parking: item.zone_parking ? `${item.zone_parking.level} ${item.zone_parking.zone}` : '',
                consecutive_number: `${item.consecutive_number}`.padStart(10, '0'),
                status: buildStatus(item.status)
            })));
        };

        get(
            'valetWSP/list_vehicles/',
            onSuccessListVehicles,
            null,
        );
    }, [get, startDate, endDate]);

    return (
        <Screen>
            <h1>Selecciona un rango de fechas y horas</h1>
            <DateRangePicker
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
            />
            <CustomTable
                headers={headers}
                body={body}
            />
        </Screen>
    );
};

export default TransactionsValetScreen;