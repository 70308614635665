import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {Button, TextField, Grid, Typography} from '@mui/material';
import initialValues from "./initialValues";
import validations from "./validations";
import { useApiContext, useSessionContext } from 'provider';

const LoginForm = () => {

    const { post_unauthorized } = useApiContext();
    const { dispatch } = useSessionContext();
    const [errorMessage, setErrorMessage] = useState(null);

    const onSuccessLogin = response => {
        dispatch({
            type: 'SET_SESSION',
            value: response.data,
        });
        localStorage.setItem('session', JSON.stringify(response.data));
    };

    const onFailureLogin = error => {
        setErrorMessage(error.response.data.message);
        setTimeout(() => setErrorMessage(null), 3000);
    }

    const onDoneLogin = setSubmitting => setSubmitting(false);

    const handleSubmit = (values, { setSubmitting }) => {
        post_unauthorized(
            'users/login/',
            values,
            onSuccessLogin,
            onFailureLogin,
            onDoneLogin(setSubmitting),
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                variant="outlined"
                                fullWidth
                                label="Usuario"
                                name="phone"
                                helperText={<ErrorMessage name="phone" />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                variant="outlined"
                                fullWidth
                                label="Contraseña"
                                name="password"
                                type="password"
                                helperText={<ErrorMessage name="password" />}
                            />
                        </Grid>
                        {
                            errorMessage && (
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="center" gutterBottom>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                        style={{ marginTop: '16px' }}
                    >
                        Iniciar Sesión
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
