import React, { useState } from 'react';
import { AppBar, CssBaseline, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Menu, Toolbar, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSessionContext } from 'provider';
import { useNavigate } from 'react-router-dom';

const TopBar = ({ handleDrawerOpen, classes }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, theme, establishmentSelected, dispatch } = useSessionContext();
    const navigate = useNavigate();

    const handleClick = event => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleLogout = () => {
        dispatch({ type: 'REMOVE_SESSION' });
        localStorage.removeItem('session');
    };

    const handleToggleDarkMode = () => {
        localStorage.setItem('themeLayout', theme === 'light' ? 'dark': 'light',);
        dispatch({
            type: 'SET_THEME',
            value: theme === 'light' ? 'dark': 'light',
        });
    }

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                    >
                        <FontAwesomeIcon icon='bars' />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {
                            establishmentSelected ? establishmentSelected.name : 'Selecciona un establecimiento'
                        }
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        {
                            user && (
                                <Typography variant="h6" noWrap>
                                    {user.name}
                                </Typography>
                            )
                        }
                        <IconButton
                            color="inherit"
                            aria-label="account"
                            aria-controls="user-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon icon='user' />
                        </IconButton>
                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <ListItem button onClick={handleToggleDarkMode}>
                                <ListItemIcon style={{ color: theme === 'light' ? '#000' : '#FFA500' }}>
                                    <FontAwesomeIcon icon={theme === 'light' ? 'moon' : 'sun'} />
                                </ListItemIcon>
                                <ListItemText primary={theme === 'light' ? 'Modo oscuro' : 'Modo claro'} />
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => {
                                navigate('/main');
                                dispatch({
                                    type: 'SET_ESTABLISHMENT_SELECTED',
                                    value: null,
                                })
                            }}>
                                <ListItemIcon style={{ color: theme === 'light' ? '#000' : '#FFF' }}>
                                    <FontAwesomeIcon icon='building' />
                                </ListItemIcon>
                                <ListItemText primary='Seleccionar establecimiento' />
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={handleLogout}>
                                <ListItemIcon style={{ color: '#FF0000' }}>
                                    <FontAwesomeIcon icon='times' />
                                </ListItemIcon>
                                <ListItemText primary="Cerrar Sesión" />
                            </ListItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
