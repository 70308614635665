import React, { useState } from 'react';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Drawer, Collapse, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './DrawerNavigation.css';
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "provider";

const DrawerNavigation = ({ handleDrawerClose, classes, open }) => {

    const navigate = useNavigate();
    const { establishmentSelected } = useSessionContext();
    const [openSubmenus, setOpenSubmenus] = useState([]);

    const handleSubMenuClick = index => {
        const newOpenSubmenus = [...openSubmenus];
        newOpenSubmenus[index] = !newOpenSubmenus[index];
        setOpenSubmenus(newOpenSubmenus);
    };

    const handleNavigate = route => navigate(route);

    const isOpenSubMenu = index => openSubmenus[index];

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{ paper: classes.drawerPaper }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    <FontAwesomeIcon icon='arrow-left' />
                </IconButton>
            </div>
            {
                establishmentSelected && (
                    <div>
                        <List>

                            {establishmentSelected.menu.map((menuItem, index) => (
                                <div key={index}>
                                    <ListItem button onClick={() => handleSubMenuClick(index)}>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                                <ListItemIcon>
                                                    <FontAwesomeIcon icon={menuItem.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary={menuItem.label} />
                                            </div>
                                            {menuItem.submenus.length > 0 && (
                                                <ListItemIcon>
                                                    <FontAwesomeIcon icon={isOpenSubMenu(index) ? 'angle-down' : 'angle-right'} />
                                                </ListItemIcon>
                                            )}
                                        </div>
                                    </ListItem>
                                    <TransitionGroup component={null}>
                                        <CSSTransition
                                            classNames="submenu"
                                            timeout={300}
                                            in={isOpenSubMenu(index)}
                                            unmountOnExit
                                        >
                                            <Collapse in={isOpenSubMenu(index)}>
                                                <List>
                                                    <Divider variant="middle" />
                                                    {menuItem.submenus.map((submenuItem, subIndex) => (
                                                        <ListItem button key={subIndex} onClick={() => handleNavigate(submenuItem.path)}>
                                                            <ListItemIcon>
                                                                <FontAwesomeIcon icon={submenuItem.icon} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={submenuItem.label} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        </CSSTransition>
                                    </TransitionGroup>
                                </div>
                            ))}
                        </List>
                    </div>
                )
            }
        </Drawer>
    );
};

export default DrawerNavigation;
