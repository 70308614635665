const darkTheme = {
    primary: {
        main: '#03477a',
        light: '#3588c1',
        dark: '#0464a4',
        contrastText: '#fff',
    },
    secondary: {
        main: '#f57c00',
        light: '#ffb74d',
        dark: '#ff9800',
        contrastText: '#fff',
    },
    error: {
        main: '#8e0011',
        light: '#d92118',
        dark: '#a40317',
        contrastText: '#fff',
    },
    info: {
        main: '#1a5d8f',
        light: '#5ca8d4',
        dark: '#2b7eb1',
        contrastText: '#fff',
    },
    success: {
        main: '#087f23',
        light: '#4caf50',
        dark: '#1b7e34',
        contrastText: '#fff',
    },
    disabled: {
        main: '#292826',
        light: '#5c5a56',
        dark: '#32312D',
        contrastText: '#fff',
    },
    rowEvenBackground: {
        main: '#101010',
    },
};

export default darkTheme;
