import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
import { useSessionContext } from "provider";
import AdminLayout from "./layout";
import { darkTheme, lightTheme } from 'themes'
import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';
import * as Screens from 'screens';

const AppRouter = () => {

    const { theme, dispatch } = useSessionContext();

    const themeLayout = createTheme({
        palette: {
            mode: theme,
            ...(theme === 'light' ? lightTheme: darkTheme),
        },
    });

    useEffect(() => {
        const session  = JSON.parse(localStorage.getItem('session')) || null;
        const themeFromLocalStorage  = localStorage.getItem('themeLayout')|| 'light';

        if(session){
            dispatch({
                type: 'SET_SESSION',
                value: session
            });
            dispatch({
                type: 'SET_THEME',
                value: themeFromLocalStorage,
            });
        }
    }, [dispatch]);

    return (
        <ThemeProvider theme={themeLayout}>
            <Routes>
                <Route
                    path='/'
                    element={
                        <PublicRoute>
                            <Screens.LoginScreen />
                        </PublicRoute>
                    }
                />
                <Route element={<AdminLayout />}>
                    <>
                        <Route
                            path={'/main'}
                            index
                            element={
                                <PrivateRoute>
                                    <Screens.MainScreen />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/transactions/parkingLots'}
                            index
                            element={
                                <PrivateRoute>
                                    <Screens.TransactionsParkingLotsScreen />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/transactions/terminals'}
                            index
                            element={
                                <PrivateRoute>
                                    <Screens.TransactionsTerminalsScreen />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/transactions/valet'}
                            index
                            element={
                                <PrivateRoute>
                                    <Screens.TransactionsValetScreen />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/transactions/valetParking'}
                            index
                            element={
                                <PrivateRoute>
                                    <Screens.TransactionsValetParkingScreen />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/transactions/parkingLotsNA'}
                            index
                            element={
                                <PrivateRoute>
                                    <Screens.TransactionsParkingLotsNAScreen />
                                </PrivateRoute>
                            }
                        />
                    </>
                </Route>
            </Routes>
        </ThemeProvider>
    );
};

export default AppRouter;

