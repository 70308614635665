import React, { useEffect, useState } from 'react';
import { Screen, CustomTable, DateRangePicker } from 'components';
import {useApiContext, useSessionContext} from 'provider';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LottieLoading from 'sources/lotties/loading.json';
import Lottie from "lottie-react";

const TransactionsScreen = ({ headers, apiEndpoint, mapData, apiEndpointDownload, apiEndpointDownloadPDF }) => {
    const [body, setBody] = useState([]);

    const sd = new Date();
    const startDay = sd.getUTCDate() > 15 ? '15' : '01';
    const [startDate, setStartDate] = useState(`${sd.getFullYear()}-${(`0${sd.getMonth() + 1}`).slice(-2)}-${startDay} 00:00:00`);
    const [endDate, setEndDate] = useState(`${new Date().toISOString().slice(0, 10)} 23:59`);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

    const { post } = useApiContext();
    const { establishmentSelected } = useSessionContext();

    const handleStartDateChange = date => setStartDate(date);
    const handleEndDateChange = date => setEndDate(date);

    useEffect(() => {
        post(
            apiEndpoint,
            { startDate, endDate, idParking: establishmentSelected.idparking },
            response => {
                console.log("achis", response);
                const responseData = response.data.data ? response.data.data : response.data;
                setBody(responseData.map(mapData));
            },
            error => console.log(error),
            null
        )
    }, [apiEndpoint, post, startDate, endDate, mapData, establishmentSelected]);

    const downloadReport = () => {
        setIsDownloading(true);
        post(
            apiEndpointDownload,
            { startDate, endDate, idParking: establishmentSelected.idparking },
            response => {
                const link = document.createElement('a');
                link.href = response.url;
                document.body.appendChild(link);
                link.click();
            },
            error => console.log(error.response),
            () => setIsDownloading(false),
        );
    };

    const downloadReportPDF = () => {
        setIsDownloadingPDF(true);
        post(
            apiEndpointDownloadPDF,
            { startDate, endDate, idParking: establishmentSelected.idparking },
            response => {
                const link = document.createElement('a');
                link.href = response.url;
                document.body.appendChild(link);
                link.click();
            },
            error => console.log(error.response),
            () => setIsDownloadingPDF(false),
        );
    };

    const downloadButton = () => {
        if (apiEndpointDownload) {
            return (
                <div
                    onClick={downloadReport}
                >
                    {
                        isDownloading ? (
                            <Lottie
                                animationData={LottieLoading}
                                loop={true}
                                style={{
                                    width: 50
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon icon='file-excel' color='#008000' size='lg' style={{ cursor: 'pointer' }} />
                        )
                    }
                </div>
            );
        }
        return null;
    }

    const downloadButtonPDF = () => {
        if (apiEndpointDownloadPDF) {
            return (
                <div
                    onClick={downloadReportPDF}
                >
                    {
                        isDownloadingPDF ? (
                            <Lottie
                                animationData={LottieLoading}
                                loop={true}
                                style={{
                                    width: 50
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon icon='file-pdf' color='#e5322d' size='lg' style={{ cursor: 'pointer', marginLeft: 10 }} />
                        )
                    }

                </div>
            );
        }
        return null;
    }

    return (
        <Screen>
            <h1>Selecciona un rango de fechas y horas</h1>
            <DateRangePicker
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
            />
            <CustomTable
                headers={headers}
                body={body}
                downloadButton={downloadButton()}
                downloadButtonPDF={downloadButtonPDF()}
            />
        </Screen>
    );
};

export default TransactionsScreen;
