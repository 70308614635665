import React from 'react';
import styles from './styles';
import { Typography, Paper } from '@mui/material';
import LoginForm from "./forms/loginForm";

function LoginScreen() {


    return (
        <div style={styles.container}>
            <Paper elevation={3} style={styles.paper}>
                <Typography variant="h4" align="center" gutterBottom>
                    Iniciar Sesión
                </Typography>
                <LoginForm />
            </Paper>
        </div>
    );
}

export default LoginScreen;
