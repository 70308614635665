import React from 'react';
import TransactionsScreen from '../transactions';

const headers = [
    { id: 'plates', label: 'Placas', sortable: true },
    { id: 'brand', label: 'Marca', sortable: true },
    { id: 'model', label: 'Modelo', sortable: true },
    { id: 'color', label: 'Color', sortable: true },
    { id: 'receives_at', label: 'Entrada', sortable: true },
    { id: 'delivered_at', label: 'Salida', sortable: true },
    { id: 'value', label: 'monto', sortable: true },
    { id: 'name_employee', label: 'Recibido por', sortable: true },
    { id: 'name_employee2', label: 'Entregado por', sortable: true },
    { id: 'paying_drive', label: 'Drive', sortable: true },
    { id: 'is_lost', label: 'Perdido', sortable: true },
];

const mapData = item => ({
    plates: item.plates,
    brand: item.brand,
    model: item.model,
    color: item.color,
    receives_at: item.receives_at,
    delivered_at: item.delivered_at,
    value: item.value,
    name_employee: item.name_employee,
    name_employee2: item.name_employee2,
    paying_drive: item.paying_drive ? 'SÍ' : 'NO',
    is_lost: item.is_lost ? 'SÍ' : 'NO',
});

const TransactionsParkingLotsScreen = () => {
    return (
        <TransactionsScreen
            headers={headers}
            apiEndpoint="valetParking/report_list/"
            apiEndpointDownload="valetParking/xlsx_report_list/"
            mapData={mapData}
        />
    );
};

export default TransactionsParkingLotsScreen;
