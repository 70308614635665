import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import TopBar from "./topbar";
import DrawerNavigation from "./drawer";
import useStyles from './styles';
import SelectEstablishment from "./selectEstablishment";

const AdminLayout = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    return (
        <div style={open ? {display: 'flex'} : {}}>
            <TopBar handleDrawerOpen={handleDrawerOpen} classes={classes} />
            <DrawerNavigation handleDrawerClose={handleDrawerClose} open={open} classes={classes} />
            <main className={classes.content}>
                <div className={classes.drawerHeader} />
                <Outlet />
            </main>
            <SelectEstablishment />
        </div>
    );
};

export default AdminLayout;
