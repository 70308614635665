import React from 'react';
import TransactionsScreen from '../transactions';
import { currencyFormat } from 'utils/formats';

const headers = [
    { id: 'ticketcode', label: 'Boleto', sortable: true },
    { id: 'checkin', label: 'Entrada', sortable: true },
    { id: 'checkout', label: 'Salida', sortable: true },
    { id: 'amountparking', label: 'Monto', sortable: true },
    { id: 'payment', label: 'Método de pago', sortable: true },
    { id: 'createdBy', label: 'Emitido por', sortable: false },
    { id: 'deliveredBy', label: 'Cobrado por', sortable: false },
    { id: 'validated', label: 'Validación', sortable: false },
];

const mapData = item => ({
    ticketcode: item.ticketcode,
    checkin: item.checkin,
    checkout: item.checkout,
    amountparking: currencyFormat(item.amountparking),
    payment: "Efectivo",
    deliveredBy: item.iduser_pay,
    createdBy: item.iduser,
    validated: item.validation,
});

const TransactionsParkingLotsNAScreen = () => {
    return (
        <TransactionsScreen
            headers={headers}
            apiEndpoint="users/qr_codes_between_dates/"
            apiEndpointDownload="users/qr_codes_between_dates_report/"
            mapData={mapData}
        />
    );
};

export default TransactionsParkingLotsNAScreen;
