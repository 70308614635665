import React from 'react';
import { Container } from '@mui/material';
import './styles.css';

export default function Screen ({ children, container }) {
    return (
        <Container
            maxWidth={container ? 'xl' : false}
            className="full-height-container"
        >
            {children}
        </Container>
    );
}
