import React from "react"
import { Navigate } from "react-router-dom";
import { useSessionContext } from 'provider';

const PrivateRoute = ({ children }) => {

    const { token } = useSessionContext();
    return token
        ? children
        : <Navigate to='/' />;
}

export default PrivateRoute;
