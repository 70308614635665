const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
    paper: {
        padding: '16px',
        width: '100%',
        maxWidth: '400px',
    },
};

export default styles;
