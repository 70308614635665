import './App.css';
import { SessionProvider, ApiProvider } from 'provider';
import AppRouter from "navigation";
import { addIcons } from "./fontAwsomeSettings";

function App() {

    addIcons();

  return (
      <SessionProvider>
          <ApiProvider>
              <AppRouter />
          </ApiProvider>
      </SessionProvider>
  );
}

export default App;
