const lightTheme= {
    primary: {
        main: '#0464a4',
        light: '#3588c1',
        dark: '#03477a',
        contrastText: '#fff',
    },
    secondary: {
        main: '#ff9800',
        light: '#ffb74d',
        dark: '#f57c00',
        contrastText: '#fff',
    },
    error: {
        main: '#a40317',
        light: '#d92118',
        dark: '#8e0011',
        contrastText: '#fff',
    },
    info: {
        main: '#2b7eb1',
        light: '#5ca8d4',
        dark: '#1a5d8f',
        contrastText: '#fff',
    },
    success: {
        main: '#1b7e34',
        light: '#4caf50',
        dark: '#087f23',
        contrastText: '#fff',
    },
    disabled: {
        main: '#32312D',
        light: '#5c5a56',
        dark: '#292826',
        contrastText: '#fff',
    },
    rowEvenBackground: {
        main: '#E6F0FA',
    },
};

export default lightTheme;
