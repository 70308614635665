import React, { createContext, useContext } from 'react';
import { useSessionContext } from './sessionProvider';
import axios from 'axios';

const ApiContext = createContext();

export const useApiContext = () => useContext(ApiContext);

const axiosConfig = {
    baseURL: 'https://apiportal.driveapp.mx/api/',
};

const ApiProvider = ({ children }) => {
    const { token, dispatch } = useSessionContext();

    const api = axios.create({
        ...axiosConfig,
        headers: { Authorization: `Token ${token}` },
    });

    const api_unauthorized = axios.create({ ...axiosConfig });

    const request = async (config, onSuccess, onError, onComplete, authorized = true) => {
        try {
            const response = authorized ? await api(config) : await api_unauthorized(config);
            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (error) {
            if (onError) {

                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 401) {
                        dispatch({ type: 'REMOVE_SESSION' });
                    } else if (statusCode === 403) {
                        dispatch({ type: 'REMOVE_SESSION' });
                    } else {
                        onError(error);
                    }
                }
            } else {
                console.error('Error en la solicitud:', error);
            }
        } finally {
            if (onComplete) {
                onComplete();
            }
        }
    };

    const createRequestConfig = (method, url, data) => ({
        method,
        url,
        data,
    });

    const post_unauthorized = (url, data, onSuccess, onError, onComplete) => {
        const config = createRequestConfig('POST', url, data);
        request(config, onSuccess, onError, onComplete, false);
    };

    const post = (url, data, onSuccess, onError, onComplete) => {
        const config = createRequestConfig('POST', url, data);
        request(config, onSuccess, onError, onComplete);
    };

    const get = (url, onSuccess, onError, onComplete) => {
        const config = createRequestConfig('GET', url);
        request(config, onSuccess, onError, onComplete);
    };

    const put = (url, data, onSuccess, onError, onComplete) => {
        const config = createRequestConfig('PUT', url, data);
        request(config, onSuccess, onError, onComplete);
    };

    const patch = (url, data, onSuccess, onError, onComplete) => {
        const config = createRequestConfig('PATCH', url, data);
        request(config, onSuccess, onError, onComplete);
    };

    const del = (url, onSuccess, onError, onComplete) => {
        const config = createRequestConfig('DELETE', url);
        request(config, onSuccess, onError, onComplete);
    };

    return (
        <ApiContext.Provider value={{ post, get, put, patch, del, post_unauthorized }}>
            {children}
        </ApiContext.Provider>
    );
};

export default ApiProvider;
