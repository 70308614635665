import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Paper,
    TableSortLabel,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    noResults: {
        textAlign: 'center',
        padding: theme.spacing(2),
        color: 'red',
    },
    tableRowEven: {
        backgroundColor: theme.palette.rowEvenBackground.main,
    },
    filterInput: {
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.dark,
            },
        },
    },
    downloadButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

const CustomTable = ({ headers, body, filterRows = true, downloadButton = null, downloadButtonPDF = null }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState(headers[0].id);
    const [order, setOrder] = useState('asc');
    const [filter, setFilter] = useState('');

    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(columnId);
    };

    const filteredData = body.filter((row) =>
        headers.some((column) => {
            const cellValue = row[column.id];
            return cellValue && cellValue.toString().toLowerCase().includes(filter.toLowerCase());
        })
    );

    const sortedData = filteredData.sort((a, b) => {
        const isAsc = order === 'asc';
        const aValue = a[orderBy];
        const bValue = b[orderBy];
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedData.length - page * rowsPerPage);

    return (
        <div>
            {
                filterRows && (
                    <TextField
                        label="Filtrar"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className={classes.filterInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <FontAwesomeIcon icon='magnifying-glass' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )
            }
            <div className={classes.downloadButton}>
                {
                    downloadButton
                }
                {
                    downloadButtonPDF
                }
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Filas por página"
                    labelDisplayedRows={({ from, to, count }) => `${from + 1} - ${to} de ${count}`}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    className={classes.tableHeader}
                                >
                                    {column.sortable ? (
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => handleSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                key={index}
                                className={index % 2 === 0 ? classes.tableRowEven : ''}
                            >
                                {headers.map((column) => (
                                    <TableCell key={column.id}>
                                        {row[column.id]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={headers.length} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {filteredData.length === 0 && (
                <div className={classes.noResults}>No se encontraron resultados.</div>
            )}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={sortedData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from + 1} - ${to} de ${count}`}
            />
        </div>
    );
};

export default CustomTable;
