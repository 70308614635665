import React from 'react';
import { TextField } from "@mui/material";

const DateRangePicker = ({ defaultStartDate, defaultEndDate, onStartDateChange, onEndDateChange }) => {

    return (
        <div>
            <TextField
                id='datetime-local'
                label='Inicio'
                type='datetime-local'
                defaultValue={defaultStartDate}
                format='yyyy-MM-dd hh:mm:ss'
                onChange={event => onStartDateChange(event.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                id='datetime-local'
                label='Fin'
                type='datetime-local'
                defaultValue={defaultEndDate}
                onChange={event => onEndDateChange(event.target.value)}
                format='yyyy-MM-dd hh:mm:ss'
                InputLabelProps={{ shrink: true }}
            />
        </div>
    );
};

export default DateRangePicker;
