import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faArrowLeft,
    faArrowDown,
    faArrowUp,
    faMagnifyingGlass,
    faUsers,
    faTicket,
    faSquareParking,
    faCar,
    faMobile,
    faUser,
    faFileExcel,
    faAngleDown,
    faAngleRight,
    faXmark,
    faSun,
    faMoon,
    faFilePdf,
    faBuilding,
} from '@fortawesome/free-solid-svg-icons';

export function addIcons() {
    library.add(
        faBars,
        faArrowLeft,
        faArrowDown,
        faArrowUp,
        faMagnifyingGlass,
        faUsers,
        faTicket,
        faSquareParking,
        faCar,
        faMobile,
        faUser,
        faFileExcel,
        faAngleDown,
        faAngleRight,
        faXmark,
        faSun,
        faMoon,
        faFilePdf,
        faBuilding,
    );
}
