import React from 'react';

import {
    List,
    ListItem,
    ListItemButton,
    DialogTitle,
    Dialog,
    ListItemText,
    Button, Divider,
} from '@mui/material'

import { useSessionContext } from 'provider';

const SelectEstablishment = () => {

    const { establishmentSelected, establishments, dispatch } = useSessionContext();

    return establishments && establishments.length > 1 ? (
        <Dialog open={establishmentSelected === null}>
            <DialogTitle sx={{ m: 0, p: 2 }}>Selecciona el establecimiento</DialogTitle>
            <Divider />
            <List>
                {
                    establishments.map(establishment => (
                        <ListItem key={establishment.idparking}>
                            <ListItemButton onClick={() => dispatch({
                                type: 'SET_ESTABLISHMENT_SELECTED',
                                value: establishment,
                            })}>
                                <ListItemText primary={establishment.name} />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
            <Divider />
            <Button color='error' onClick={() => {
                dispatch({ type: 'REMOVE_SESSION' });
                localStorage.removeItem('session');
            }}>
                Cerrar sesión
            </Button>
        </Dialog>

    ) : null;
};

export default SelectEstablishment;
